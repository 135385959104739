<script>
  import Post from '../../compenents/postCommon.vue'
  import { translateTitle } from '@/utils/i18n'
  import config from '../../config'
  export default {
    components: {
      Post,
    },
    data() {
      return {
        ...config,
        params: {
          pic: null,
          title: null,
          begin_time: null,
          end_time: null,
          link: null,
          place: null,
          position: 1,
          tag: null,
          is_show: 1,
          upload_type: 1,
          pic_url: null,
          video_type: 1,
          video_url: null,
          video: null,
          ac_op: -1,
          show_label: -1,
          show_title: null,
          class_id: null,
        },
      }
    },
    computed: {
      column() {
        return [
          {
            label: '是否显示',
            prop: 'is_show',
            type: 'radio',
            list: this.whetherList1,
          },
          {
            label: '广告备注',
            prop: 'title',
          },
          {
            label: '类型选择',
            prop: 'upload_type',
            type: 'radio',
            list: this.typeList,
          },
          {
            label: '上传视频',
            prop: 'video_type',
            type: 'radio',
            list: this.videoTypeList,
            isHide: this.params.upload_type != 2,
          },
          {
            label: '',
            prop: 'video_url',
            type: 'uploadVideo',
            eventName: 'handleVideoUrl',
            isHide: this.params.video_type != 2,
          },
          {
            label: '上传图片',
            prop: 'pic',
            type: 'uploadImage',
            eventName: 'handleImageUrl',
            tips: this.translateTitle(
              '<p>组件样式一建议尺寸：750*328px</p><p>组件样式二建议尺寸：750*234px</p><p>组件样式三建议尺寸：750*140px</p>'
            ),
          },
          {
            label: '跳转',
            prop: 'link',
            // tips: '选填，可以配置app内部任意页面的跳转链接。<a href="http://help_for_magapp.mydoc.io/?t=177452/" target="_blank">查看示例</a>',
            tips: `${this.translateTitle(
              '选填，可以配置app内部任意页面的跳转链接。'
            )}<a href="http://help_for_magapp.mydoc.io/?t=177452/" target="_blank">${this.translateTitle(
              '查看示例'
            )}</a>`,
            clickText: '选择跳转地址',
            clickEventName: 'handleCheckLink',
          },
          {
            label: '位置',
            prop: 'position',
            type: 'number',
            tips: '广告在列表中显示的固定位置，数字相同时最新发布的广告靠前',
          },
          {
            label: '是否显示广告标签',
            prop: 'show_label',
            type: 'radio',
            list: this.whetherList2,
          },
          {
            label: '开始时间',
            prop: 'begin_time',
            timeType: 'datetime',
            type: 'startDaterangePicker',
          },
          {
            label: '结束时间',
            prop: 'end_time',
            timeType: 'datetime',
            type: 'endDaterangePicker',
          },
        ]
      },
    },
    methods: {
      translateTitle,
    },
  }
</script>
